<template>
  <v-container>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-4">
        <v-icon color="primary" left>mdi-account-group</v-icon>
        Usuários
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          style="max-width: 225px"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        ></v-text-field>
        <v-select
          v-model="selectedPerfil"
          :items="perfis"
          label="Perfil"
          item-text="descricao"
          item-value="id"
          class="mx-2"
          style="max-width: 225px"
          prepend-inner-icon="mdi-account"
          dense
          clearable
          solo
          flat
          hide-details
          hide-no-data
          outlined
          full-width
        >
        </v-select>
        <v-btn color="secondary" @click="addUsuario">Adicionar</v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-5">
        <v-data-table
          v-if="usuarios"
          :headers="headers"
          :items="usuarios"
          :items-per-page="10"
          :search="search"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
          @click:row="goToUsuario"
        >
          <template v-slot:item.status="{ item }">
            {{ item.status | status }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <AddUsuario
      v-if="dialogUsuario"
      :dialogUsuario.sync="dialogUsuario"
      :edit="edit"
      :item="usuario"
      @fetch-usuario="fetchUsuarios"
    />
  </v-container>
</template>

<script>
import { getUsuarios } from "@/api/admin/users.js";

export default {
  name: "Usuarios",

  components: {
    AddUsuario: () => import("./components/AddUsuario.vue"),
  },

  data() {
    return {
      loading: false,
      dialogUsuario: false,
      usuarios: [],
      audists: [],
      usuario: {},
      selectedPerfil: null,
      search: "",
      perfis: [
        { id: 1, descricao: "ADMIN" },
        { id: 2, descricao: "GESTOR" },
        { id: 3, descricao: "COLABORADOR" },
        { id: 4, descricao: "CLIENTE" },
      ],
      edit: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "E-mail",
          value: "email",
        },
        {
          text: "Celular",
          value: "celular",
        },
        {
          text: "Perfis",
          value: "perfis",
        },
        {
          text: "Status",
          value: "status",
        },
      ];
    },
  },

  watch: {
    selectedPerfil() {
      if (this.selectedPerfil) {
        this.fetchUsuarios(`?perfil_id=${this.selectedPerfil}`);
      } else {
        this.fetchUsuarios();
      }
    },
  },

  methods: {
    addUsuario() {
      this.dialogUsuario = true;
      this.edit = false;
    },
    goToUsuario(item) {
      this.usuario = { ...item };
      this.dialogUsuario = true;
      this.edit = true;
    },
    async fetchUsuarios(filtro) {
      try {
        this.loading = true;

        const response = await getUsuarios(filtro ? filtro : "");

        this.usuarios = response;

        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchUsuarios();
  },
};
</script>

<style></style>
